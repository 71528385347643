const list = [
	'/',
	'/about/dlipay',
	'/system/dliapp',
	'/system/market',
	'/system/order',
	'/system/visual',
	'/system/vending',
	'/system/uva',
	'/system/train',
	'/polymer/pay',
	'/polymer/funds',
	'/maker/cashier',
	'/maker/vip',
	'/maker/erp',
	'/maker/sales',
	'/maker/store',
	'/maker/intergration',
	'/maker/league',
	'/space',
	'/nursery',
	'/community',
	'/pay/number',
	'/pay/order',
	'/pay/education',
	'/case/success',
	'/case/retail',
	'/case/repast',
	'/aboutUs',
	'/policy/merchantPrivacy',
	'/policy/merchantProtocol',
	'/policy/acquisitionPrivacy',
	'/policy/acquisitionProtocol',
	'/policy/storePrivacy',
	'/policy/storeProtocol',
	'/policy/sdk',
	'/pay/beauty',
	'/pay/fresh',
	'/pay/station',
	'/pay/confinement'
]

export default list