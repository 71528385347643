import Vue from 'vue'
import VueRouter from 'vue-router'
import routes from './route.js'
import list from './whiteList.js'

Vue.use(VueRouter)

const router = new VueRouter({
  routes
})

router.beforeEach((to, from, next) => {
	// console.log(to, from, 77777)
	if(list.indexOf(to.path) > -1) {
		next()
	}else if(!to.path) {
		next(from.path)
	}
	
})

export default router
