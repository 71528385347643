<template>
	<div id="app">
		<el-container>
			<el-header>
				<div class="hidden-xs">
					<el-menu mode="horizontal" :default-active="this.$route.path" :router="true" @select="select">
						<div class="logo">
							<img src="https://dilifu.oss-cn-shenzhen.aliyuncs.com/static/image/public/logo.png" class="auto-img" alt="logo">
						</div>
						<!-- <div class="play">
							<label for="audio" @click="play">
								<img :src="player ? 'https://dilifu.oss-cn-shenzhen.aliyuncs.com/static/image/public/play.svg' : 'https://dilifu.oss-cn-shenzhen.aliyuncs.com/static/image/public/pause.svg'"
									class="auto-img" alt="音频">
								<audio id="audio" src="https://dilifu.oss-cn-shenzhen.aliyuncs.com/static/image/video/dli.mp3" style="display: none;" autoplay
									loop></audio>
							</label>
						</div> -->
						<el-menu-item index="/aboutUs">关于我们</el-menu-item>
						<el-submenu index="/case">
							<template slot="title">合作加盟</template>
							<el-menu-item index="/case/success">合作商户</el-menu-item>
							<el-menu-item index="/case/retail">新零售加盟</el-menu-item>
							<el-menu-item index="/case/repast">餐饮加盟</el-menu-item>
						</el-submenu>
						<el-submenu index="/pay">
							<template slot="title">支付+</template>
							<el-menu-item index="/pay/number">智慧零售</el-menu-item>
							<el-menu-item index="/pay/order">智慧餐饮</el-menu-item>
							<el-menu-item index="/pay/education">智慧教育</el-menu-item>
							<el-menu-item index="/pay/fresh">智慧生鲜</el-menu-item>
							<el-menu-item index="/pay/beauty">智慧美业</el-menu-item>
							<el-menu-item index="/pay/station">智慧油站</el-menu-item>
							<el-menu-item index="/pay/confinement">智慧月子所</el-menu-item>
						</el-submenu>
						<el-submenu index="/serve">
							<template slot="title">产品服务</template>
							<el-submenu index="/about">
								<template slot="title">关于滴沥付</template>
								<el-menu-item index="/about/dlipay">滴沥付介绍</el-menu-item>
							</el-submenu>
							<el-submenu index="/system">
								<template slot="title">系统支持</template>
								<el-menu-item index="/system/dliapp">滴沥付APP</el-menu-item>
								<el-menu-item index="/system/market">商超Sass系统</el-menu-item>
								<el-menu-item index="/system/order">餐饮Sass系统</el-menu-item>
								<el-menu-item index="/system/visual">可视化系统</el-menu-item>
								<el-menu-item index="/system/vending">自动售卖机Sass系统</el-menu-item>
								<el-menu-item index="/system/uva">配送+无人车运力系统</el-menu-item>
								<el-menu-item index="/system/train">供应链系统</el-menu-item>
							</el-submenu>
							<el-submenu index="/polymer">
								<template slot="title">聚合支付</template>
								<el-menu-item index="/polymer/pay">聚合支付</el-menu-item>
								<el-menu-item index="/polymer/funds">资金解决方案</el-menu-item>
							</el-submenu>
							<el-submenu index="/maker">
								<template slot="title">神兽创客(线上平台)</template>
								<el-menu-item index="/maker/cashier">线上线下收银</el-menu-item>
								<el-menu-item index="/maker/vip">会员卡</el-menu-item>
								<!-- <el-menu-item index="/maker/erp">进销存ERP</el-menu-item> -->
								<el-menu-item index="/maker/sales">营销插件</el-menu-item>
								<el-menu-item index="/maker/store">店铺助手</el-menu-item>
								<el-menu-item index="/maker/intergration">数字经营一体化</el-menu-item>
								<el-menu-item index="/maker/league">商家联盟</el-menu-item>
							</el-submenu>
							<el-menu-item index="/space">神兽创客空间(线下运营)</el-menu-item>
							<el-menu-item index="/nursery">小神兽之家(幼儿园缴费平台)</el-menu-item>
							<el-menu-item index="/community">菠萝芒果橙(社区购物平台)</el-menu-item>
						</el-submenu>
						<el-menu-item index="/">首页</el-menu-item>
					</el-menu>
				</div>
				<div class="visible-xs">
					<div class="flex">
						<div class="xs-logo">
							<img src="https://dilifu.oss-cn-shenzhen.aliyuncs.com/static/image/public/xs-logo.png" class="auto-img" alt="logo">
						</div>
						<div class="flex">
							<!-- <label class="xs-play" for="audio" @click="play">
								<img :src="player ? 'https://dilifu.oss-cn-shenzhen.aliyuncs.com/static/image/public/play.svg' : 'https://dilifu.oss-cn-shenzhen.aliyuncs.com/static/image/public/pause.svg'"
									class="auto-img" alt="">
									<audio id="audio" src="https://dilifu.oss-cn-shenzhen.aliyuncs.com/static/image/video/dli.mp3" style="display: none;" autoplay loop></audio>
							</label> -->
							<div class="more" @click="togglNav">
								<img src="https://dilifu.oss-cn-shenzhen.aliyuncs.com/static/image/public/more.svg" class="auto-img" alt="更多">
							</div>
						</div>
					</div>
					<div class="back" @click="back">
						<i class="el-icon-arrow-left"></i>
					</div>
				</div>
			</el-header>
			<el-main>
				<div class="container-fluid">
					<keep-alive>
						<router-view></router-view>
					</keep-alive>
				</div>
			</el-main>
			<el-footer>
				<div class="footer hidden-xs">
					<div class="info-box">
						<div style="display: flex;align-items: flex-start;">
							<div style="font-size: 60px;font-weight: 400;line-height: 82.32px;color: #fff;">滴沥科技</div>
							<div class="info-list">
								<div class="info-item" v-for="(item, index) in list" :key="index">{{item.title}}</div>
							</div>
						</div>
						
						<div class="product">
							<div class="company-info">
								<div>滴沥（广州）信息科技有限公司</div>
								<div>电话：17316868487</div>
								<div>邮箱：17316868487@sohu.com</div>
								<div>工作时间：09:00-18:00</div>
							</div>
							<div class="pro-box">
								<div>
									<img src="./assets/public/ss.jpg" alt="图片" />
									<div>神兽创客</div>
								</div>
								<div>
									<img src="./assets/public/dl-agent.png" alt="图片" style="margin: 0 30px;" />
									<div>滴沥付展业端</div>
								</div>
								<div>
									<img src="./assets/public/dl-merchant.png" alt="图片" />
									<div>滴沥付商家端</div>
								</div>
							</div>
						</div>
						<div class="line" style="width: 100%;border: 1px solid #A6A6A6;"/>
						<div class="record-box">
							<div class="privacy">
								<div class="privacy-item" @click="toPage('/policy/merchantPrivacy')">商家端隐私政策</div>
								<div class="privacy-item" @click="toPage('/policy/merchantProtocol')">商家端用户服务协议</div>
								<div class="privacy-item" @click="toPage('/policy/acquisitionPrivacy')">展业端隐私政策</div>
								<div class="privacy-item" @click="toPage('/policy/acquisitionProtocol')">展业端用户服务协议</div>
								<div class="privacy-item" @click="toPage('/policy/storePrivacy')">门店端隐私政策</div>
								<div class="privacy-item" @click="toPage('/policy/storeProtocol')">门店端用户服务协议</div>
							</div>
							<div class="record">
								<div><a href="https://beian.miit.gov.cn">© 2013-2022 滴沥科技 粤ICP备20024035号</a></div>
								<div><a href="https://beian.miit.gov.cn">© 2013-2022 滴沥科技 粤ICP备20024035号</a></div>
								<div><a
										href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=44011402000786">©
										2013-2022 滴沥科技 <img src="https://dilifu.oss-cn-shenzhen.aliyuncs.com/static/image/public/jc.png"
											style="width: 20px;height: 20px;"> 粤公网安备 44011402000786号</a>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="visible-xs" style="background-color: #000;color: #fff;padding: 15px 0;text-align: center;">
					<div>电话：17316868487</div>
					<div>邮箱：17316868487@sohu.com</div>
					<div><a href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=44011402000786">©
							2013-2022 滴沥科技 <img src="https://dilifu.oss-cn-shenzhen.aliyuncs.com/static/image/public/jc.png" style="width: 15px;height: 15px;"> 粤公网安备
							44011402000786号</a></div>
					<div><a href="https://beian.miit.gov.cn">© 2013-2022 滴沥科技 粤ICP备20024035号</a></div>
					<div><a href="https://beian.miit.gov.cn">© 2013-2022 滴沥科技 粤ICP备20024035号</a></div>
				</div>
			</el-footer>
		</el-container>
		<el-drawer :visible.sync="drawer" size="65%" direction="ttb" :show-close="false">
			<el-menu class="xs-menu" :default-active="this.$route.path" :router="true" @select="select">
				<el-menu-item index="/">首页</el-menu-item>
				<el-submenu index="/serve">
					<template slot="title">产品服务</template>
					<el-submenu index="/about">
						<template slot="title">关于滴沥付</template>
						<el-menu-item index="/about/dlipay">滴沥付介绍</el-menu-item>
					</el-submenu>
					<el-submenu index="/system">
					<template slot="title">系统支持</template>
						<el-menu-item index="/system/dliapp">滴沥付APP</el-menu-item>
						<el-menu-item index="/system/market">商超Sass系统</el-menu-item>
						<el-menu-item index="/system/order">餐饮Sass系统</el-menu-item>
						<el-menu-item index="/system/visual">可视化系统</el-menu-item>
						<el-menu-item index="/system/vending">自动售卖机Sass系统</el-menu-item>
						<el-menu-item index="/system/uva">配送+无人车运力系统</el-menu-item>
						<el-menu-item index="/system/train">供应链系统</el-menu-item>
					</el-submenu>
					<el-submenu index="/polymer">
						<template slot="title">聚合支付</template>
						<el-menu-item index="/polymer/pay">聚合支付</el-menu-item>
						<el-menu-item index="/polymer/funds">资金解决方案</el-menu-item>
					</el-submenu>
					<el-submenu index="/maker">
						<template slot="title">神兽创客(线上平台)</template>
						<el-menu-item index="/maker/cashier">线上线下收银</el-menu-item>
						<el-menu-item index="/maker/vip">会员卡</el-menu-item>
						<!-- <el-menu-item index="/maker/erp">进销存ERP</el-menu-item> -->
						<el-menu-item index="/maker/sales">营销插件</el-menu-item>
						<el-menu-item index="/maker/store">店铺助手</el-menu-item>
						<el-menu-item index="/maker/intergration">数字经营一体化</el-menu-item>
						<el-menu-item index="/maker/league">商家联盟</el-menu-item>
					</el-submenu>
					<el-menu-item index="/space">神兽创客空间(线下运营)</el-menu-item>
					<el-menu-item index="/nursery">小神兽之家(幼儿园缴费平台)</el-menu-item>
					<el-menu-item index="/community">菠萝芒果橙(社区购物平台)</el-menu-item>
				</el-submenu>
				<el-submenu index="/pay">
					<template slot="title">支付+</template>
					<el-menu-item index="/pay/number">智慧零售</el-menu-item>
					<el-menu-item index="/pay/order">智慧餐饮</el-menu-item>
					<el-menu-item index="/pay/education">智慧教育</el-menu-item>
					<el-menu-item index="/pay/fresh">智慧生鲜</el-menu-item>
					<el-menu-item index="/pay/beauty">智慧美业</el-menu-item>
					<el-menu-item index="/pay/station">智慧油站</el-menu-item>
					<el-menu-item index="/pay/confinement">智慧月子所</el-menu-item>
				</el-submenu>
				<el-submenu index="/case">
					<template slot="title">合作加盟</template>
					<el-menu-item index="/case/success">合作商户</el-menu-item>
					<el-menu-item index="/case/retail">新零售加盟</el-menu-item>
					<el-menu-item index="/case/repast">餐饮加盟</el-menu-item>
				</el-submenu>
				<el-menu-item index="/aboutUs">关于我们</el-menu-item>
			</el-menu>
		</el-drawer>
	</div>
</template>

<script>
	export default {
		name: 'App',
		

		data() {
			return {
				active: '/',
				list: [
					{ title: '系统支持', path: '/system/dliapp' },
					{ title: '合作案例', path: '/case/success' },
					{ title: '聚合支付', path: '/polymer/pay' },
					{ title: '线上平台', path: '/maker/cashier' },
					{ title: '关于我们', path: '/aboutUs' },
					{ title: '支付生活', path: '/pay/number' }
				],
				player: true,
				drawer: false,
				scroll: 0,
			}
		},
		
		mounted() {
			window.addEventListener('scroll', this.handleScroll)
		},
		
		activated() {
			setTimeout(() => {
				// console.log(this.scroll, 7777)
				if(this.scroll > 0){
					window.scrollTo(0, this.scroll);
					this.scroll = 0;
					window.addEventListener('scroll', this.handleScroll);
				}
			}, 10)
		},
		
		deactivated(){
		    window.removeEventListener('scroll', this.handleScroll);
		},

		methods: {
			// play() {
			// 	let audio = document.getElementById('audio')
			// 	this.player = !this.player
			//  if (audio) {
			// 		if (this.player) {
			// 			audio.play()
			// 		} else {
			// 			audio.pause()
			// 		}
			// 	}
			// },
			
			handleScroll () {
			    this.scroll  = document.documentElement &&  document.documentElement.scrollTop
			    // console.log(this.scroll)
			},
			
			back() {
				// this.$router.back()
				history.go(-1)
			},
			
			togglNav() {
				this.drawer = !this.drawer
			},
			
			select(index) {
				sessionStorage.setItem('path', index)
				this.drawer = false
				window.scroll(0, 0)
			},
			
			toPage(router) {
				this.active = router
				this.$router.push(router)
				sessionStorage.setItem('path', router)
				window.scroll(0, 0)
			}
		}
	}
</script>

<style lang="less">
	body {
		margin: 0;
		padding: 0;
	}
	
	.el-carousel__arrow{
		background-color: rgba(31,45,61,.6) !important;
	}
	
	.el-drawer__body>*{
		padding-top: 60px !important;
	}

	#app {
		font-family: Avenir, Helvetica, Arial, sans-serif;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		// text-align: center;
	}
	
	.back{
		padding: 0 15px;
		background: #fff;
		font-size: 30px;
	}

	.el-menu--horizontal>.el-menu-item {
		float: right !important;
	}
	
	/deep/.el-carousel__button{
		width: 25px !important;
		height: 4px !important;
		border-radius: 8px !important;
		background: #ccc !important;
	}
	
	/deep/.is-active{// 指示器激活按钮
	    background: rgba(28, 122, 235, 1) !important;
	}

	.el-menu--horizontal>.el-submenu {
		float: right !important;
	}

	.el-footer {
		padding: 0 !important;
	}

	.el-main {
		padding: 0 !important;
		overflow: visible;
	}
	
	.xs-margin{
		margin-top: 103px !important;
	}

	.el-header {
		padding: 0 !important;
		z-index: 1999 !important;
		position: fixed;
		left: 0;
		right: 0;
		background-color: #fff;
	}

	.auto-img {
		display: block;
		width: 100%;
		height: 100%;
		image-rendering: optimizeQuality;
	}

	.logo {
		width: 180px;
		height: 40px;
		float: left;
		margin: 10px 15px;
	}

	.footer {
		width: 100%;
		padding-bottom: 30px;
		overflow: hidden;
		background-color: #000;
		
		.product{
			display: flex;
			align-items: center;
			text-align: center;
			font-size: 18px;
			font-weight: 400;
			color: #fff;
			line-height: 24.7px;
			
			.pro-box{
				display: flex;
				align-items: flex-end;
			}
			
			.pro-box>div>img{
				width: 120px;
				height: 120px;
			}
			
			.pro-box>div>div{
				margin-top: 10px;
			}
		}
		
		
		
		.company-info{
			font-size: 20px;
			color: #fff;
			line-height: 27.44px;
			text-align: left;
			font-weight: 400;
			margin-right: 196px;
		}
		
		.company-info>div{
			margin-bottom: 22px;
		}

		.info-box {
			width: 916px;
			height: 100%;
			position: relative;
			margin: 0 auto;
			padding-top: 130px;

			.info-list {
				display: flex;
				width: 236px;
				color: #fff;
				flex-wrap: wrap;
				font-size: 20px;
				margin-bottom: 121px;
				margin-left: auto;

				.info-item {
					margin-right: 76px;
					margin-bottom: 29px;
					cursor: pointer;
				}

				.info-item:nth-child(2n) {
					margin-right: 0;
				}
				
				.info-item:hover{
					text-decoration: underline;
				}
			}

			.record-box {
				display: flex;
				align-items: flex-start;
				// margin-top: 305px;

				.privacy {
					margin-right: 100px;
					flex: 1;
					position: relative;
					color: #fff;
					font-size: 14px;

					.privacy-item {
						float: left;
						cursor: pointer;
						margin: 2px;
					}

					.privacy-item:hover {
						text-decoration: underline;
					}
				}

				.record {
					display: flex;
					flex-wrap: wrap;
					flex: 1;
					justify-content: flex-end;
					// font-size: 16px !important;
				}
			}
		}
	}

	.play {
		width: 20px;
		height: 20px;
		float: right;
		margin: 20px 30px;
	}

	.xs-logo {
		width: 130px;
		height: 40px;
		margin: 10px 15px;
	}

	.more {
		width: 30px;
		height: 30px;
		margin: 15px;
	}

	.xs-play {
		width: 25px;
		height: 25px;
		margin: 0 15px;
	}

	.flex {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
	
	.xs-menu{
		padding-top: 20px;
	}
	
	.big-title{
		font-size: 48px;
		color: #000;
		text-align: center;
		font-family: '思源黑体';
		margin: 80px 0;
	}
	
	.whole-line{
		width: 100px;
		height: 4px;
		background: #1C7AEB;
		border-radius: 8px;
		margin: 30px auto 60px;
		box-shadow: 0px 2px 4px 0px #1C7AEB;
	}
	
	.lg-margin{
		margin-top: 60px;
	}
</style>
