const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('@/views/home/home.vue'),
	meta:{ keepAlive: true }
  },
  {
    path: '/about/dlipay',
    name: 'DLipay',
    component: () => import('@/views/about/dlipay.vue'),
	meta:{ keepAlive: true }
  },
  {
    path: '/system/dliapp',
    name: 'DLiapp',
    component: () => import('@/views/system/dliapp.vue'),
	meta:{ keepAlive: true }
  },
  {
    path: '/system/market',
    name: 'Market',
    component: () => import('@/views/system/marketSass.vue'),
	meta:{ keepAlive: true }
  },
  {
    path: '/system/order',
    name: 'orderSass',
    component: () => import('@/views/system/orderSass.vue'),
	meta:{ keepAlive: true }
  },
  {
    path: '/system/visual',
    name: 'Visual',
    component: () => import('@/views/system/visual.vue'),
	meta:{ keepAlive: true }
  },
  {
    path: '/system/vending',
    name: 'Vending',
    component: () => import('@/views/system/vending.vue'),
	meta:{ keepAlive: true }
  },
  {
    path: '/system/uva',
    name: 'UVA',
    component: () => import('@/views/system/uva.vue'),
	meta:{ keepAlive: true }
  },
  {
    path: '/system/train',
    name: 'Train',
    component: () => import('@/views/system/train.vue'),
	meta:{ keepAlive: true }
  },
  {
    path: '/polymer/pay',
    name: 'Pay',
    component: () => import('@/views/polymer/pay.vue'),
	meta:{ keepAlive: true }
  },
  {
    path: '/polymer/funds',
    name: 'Funds',
    component: () => import('@/views/polymer/funds.vue'),
	meta:{ keepAlive: true }
  },
  {
    path: '/maker/cashier',
    name: 'Cashier',
    component: () => import('@/views/maker/cashier.vue'),
	meta:{ keepAlive: true }
  },
  {
    path: '/maker/vip',
    name: 'Vip',
    component: () => import('@/views/maker/vip.vue'),
	meta:{ keepAlive: true }
  },
  {
    path: '/maker/erp',
    name: 'Erp',
    component: () => import('@/views/maker/erp.vue'),
	meta:{ keepAlive: true }
  },
  {
    path: '/maker/sales',
    name: 'Sales',
    component: () => import('@/views/maker/sales.vue'),
	meta:{ keepAlive: true }
  },
  {
    path: '/maker/store',
    name: 'Store',
    component: () => import('@/views/maker/store.vue'),
	meta:{ keepAlive: true }
  },
  {
    path: '/maker/intergration',
    name: 'Intergration',
    component: () => import('@/views/maker/intergration.vue'),
	meta:{ keepAlive: true }
  },
  {
    path: '/maker/league',
    name: 'League',
    component: () => import('@/views/maker/league.vue'),
	meta:{ keepAlive: true }
  },
  {
    path: '/space',
    name: 'Space',
    component: () => import('@/views/space/space.vue'),
	meta:{ keepAlive: true }
  },
  {
    path: '/nursery',
    name: 'Nursery',
    component: () => import('@/views/nursery/nursery.vue'),
	meta:{ keepAlive: true }
  },
  {
    path: '/community',
    name: 'Community',
    component: () => import('@/views/community/community.vue'),
	meta:{ keepAlive: true }
  },
  {
    path: '/pay/number',
    name: 'Number',
    component: () => import('@/views/pay/number.vue'),
	meta:{ keepAlive: true }
  },
  {
    path: '/pay/order',
    name: 'Order',
    component: () => import('@/views/pay/order.vue'),
	meta:{ keepAlive: true }
  },
  {
    path: '/pay/education',
    name: 'Education',
    component: () => import('@/views/pay/education.vue'),
	meta:{ keepAlive: true }
  },
  {
    path: '/pay/fresh',
    name: 'Fresh',
    component: () => import('@/views/pay/fresh.vue'),
  	meta:{ keepAlive: true }
  },
  {
    path: '/pay/beauty',
    name: 'Beauty',
    component: () => import('@/views/pay/beauty.vue'),
  	meta:{ keepAlive: true }
  },
  {
    path: '/pay/station',
    name: 'Station',
    component: () => import('@/views/pay/station.vue'),
  	meta:{ keepAlive: true }
  },
  {
    path: '/pay/confinement',
    name: 'Confinement',
    component: () => import('@/views/pay/confinement.vue'),
  	meta:{ keepAlive: true }
  },
  {
    path: '/case/success',
    name: 'Success',
    component: () => import('@/views/case/success.vue'),
	meta:{ keepAlive: true }
  },
  {
    path: '/case/retail',
    name: 'Retail',
    component: () => import('@/views/case/retail.vue'),
	meta:{ keepAlive: true }
  },
  {
    path: '/case/repast',
    name: 'Repast',
    component: () => import('@/views/case/repast.vue'),
	meta:{ keepAlive: true }
  },
  {
    path: '/aboutUs',
    name: 'aboutUs',
    component: () => import('@/views/aboutUs/aboutUs.vue'),
	meta:{ keepAlive: true }
  },
  {
    path: '/policy/merchantPrivacy',
    name: 'MerchantPrivacy',
    component: () => import('@/views/policy/merchantPrivacy.vue'),
	meta:{ keepAlive: true }
  },
  {
    path: '/policy/merchantProtocol',
    name: 'MerchantProtocol',
    component: () => import('@/views/policy/merchantProtocol.vue'),
	meta:{ keepAlive: true }
  },
  {
    path: '/policy/acquisitionPrivacy',
    name: 'AcquisitionPrivacy',
    component: () => import('@/views/policy/acquisitionPrivacy.vue'),
	meta:{ keepAlive: true }
  },
  {
    path: '/policy/acquisitionProtocol',
    name: 'AcquisitionProtocol',
    component: () => import('@/views/policy/acquisitionProtocol.vue'),
	meta:{ keepAlive: true }
  },
  {
    path: '/policy/storePrivacy',
    name: 'StorePrivacy',
    component: () => import('@/views/policy/storePrivacy.vue'),
	meta:{ keepAlive: true }
  },
  {
    path: '/policy/storeProtocol',
    name: 'StoreProtocol',
    component: () => import('@/views/policy/storeProtocol.vue'),
	meta:{ keepAlive: true }
  },
  {
    path: '/policy/sdk',
    name: 'SDK',
    component: () => import('@/views/policy/sdk.vue'),
	meta:{ keepAlive: true }
  }
]

export default routes